import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Userindex = () => {
    const headers = {
        'authorizationToken': sessionStorage.loginSession,
    };

    const [users,setUsers] = useState([])
    const [refresh,setrefresh] = useState(false)

    useEffect(()=>{
        axios.get("https://80vmkcod71.execute-api.ap-southeast-2.amazonaws.com/get_unacceptable_users/", { headers }).then((response)=>{
            setUsers(response.data.data)
            // sessionStorage.setItem("useremail", response.data.data[0].email);
        }).catch((error)=>{
            toast.error(error.response.data)
            //console.log("error",error.response.data);
            sessionStorage.clear()
        })
    },[refresh])

    // console.log("users",users);


    let confirmactionUser = (email)=>{
        const headers = {
            'authorizationToken': sessionStorage.loginSession,
        };
        axios.post("https://t96w2dcutg.execute-api.ap-southeast-2.amazonaws.com/admin_confirmation_users/",{user_email: email},{headers}).then(response=>{
            // console.log(response);
            toast.success(response.data)
            setrefresh(!refresh)
            
        }).catch(err=>{
            console.log("Error: " + err);
            
        })
    }
    let rejecttheUser = (email)=>{
        const headers = {
            'authorizationToken': sessionStorage.loginSession,
        };
        axios.post("https://v110sxp4y1.execute-api.ap-southeast-2.amazonaws.com/rejecte_user_request/",{user_email: email},{headers}).then(response=>{
            // console.log(response);
            toast.success(response.data.message)
            setrefresh(!refresh)
            
        }).catch(err=>{
            console.log("Error: " + err);
            
        })
    }

  return (
    <div className=' mt-2 user-management'>
        <ToastContainer autoClose={20000} />

        <button className='my-2' onClick={()=>setrefresh(!refresh)}>Refresh</button>

        <table className="table table-bordered">
        <thead>
            <tr>
            <th scope="col">username</th>
            <th scope="col">organization</th>
            <th scope="col">country</th>
            <th scope="col">Role</th>
            <th scope="col">Email</th>
            <th scope="col">Action</th>
            </tr>
        </thead>
        <tbody>
        {users && users.length > 0 ? (
            users.map((user,index) =>(
                <tr key={index}>
                    <td>{user.username}</td>
                    <td>{user.organization_name}</td>
                    {/* <td>Imedx</td> */}
                    <td>{user.country_name}</td>
                    {/* <td>India</td> */}
                    <td>{user.role}</td>
                    <td>{user.email}</td>
                    <td>
                        <button className='p-2 mx-2' onClick={()=>confirmactionUser(user.email)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0"/>
                        </svg>
                        </button>
                        <button className='p-2' onClick={()=>rejecttheUser(user.email)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                            </svg>
                        </button>
                    </td>
                </tr>
            ))
        ) : ( <p>No data available</p>
        )}
        </tbody>
        </table>


    </div>
  )
}

export default Userindex