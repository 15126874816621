import React, { useEffect, useState } from 'react';
import './ManageCountries.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
// import apiClient from '../../../server';
import axiosInstance from '../../../axiosInterceptor';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ManageCountries = () => {
  const [countries, setCountries] = useState();
  const [showModal, setShowModal] = useState(false);
  const [refresh, setrefresh] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [editingCountry, setEditingCountry] = useState(null);
  const [countryName, setCountryName] = useState('');
  const [users, setUsers] = useState([]);
  const[unacceptedUser,setUnacceptedUser]=useState([]);
  const [assignedCountryIds, setAssignedCountryIds] = useState([]); 

  const handleAdd = () => {
    setEditingCountry(null);
    setCountryName('');
    setShowModal(true);
  };

  const handleEdit = (country) => {
    setEditingCountry(country);
    setCountryName(country.country_name);
    setShowModal(true);
  };

  const handleDelete = (countryId) => {
   
    setEditingCountry(countryId);

    axiosInstance.delete(`https://61jsf18ebc.execute-api.ap-southeast-2.amazonaws.com/delete_admin_countries/?countryId=${countryId}`).then((response) => {
      // setrefresh(!refresh)
      toast.success(response.data.message);
      setShowModal(false)
      //console.log("response",response);
    })
    // .catch(err=>{
    //   console.log("Error: " + err);
    // })
    .catch((err) => {
      setShowConfirmDialog(false);

      // Handle specific backend errors
      if (
        err.response &&
        err.response.data &&
        err.response.data.error &&
        err.response.data.error.includes("violates foreign key constraint")
      ) {
        toast.error("This Country is assigned to users and cannot be deleted.");
      } else {
        toast.error("Failed to delete role. Please try again.");
      }
    });
    // setShowConfirmDialog(true);
  };

  const confirmDelete = () => {
    setCountries(countries.filter((country) => country.id !== editingCountry));
    setShowConfirmDialog(false);
  };
 


  const handleSave = () => {
    if (!countryName.trim()) {
      toast.error("Country name cannot be empty!");
      return;
    }

    if(!editingCountry?.country_id){
      axiosInstance.post("https://vcpw31x9d3.execute-api.ap-southeast-2.amazonaws.com/post_admin_countries/",{countryName}).then((response) => {
        // setrefresh(!refresh)
        toast.success(response.data.message);
        setShowModal(false)
        //console.log("response",response);
      })
      // .catch(err=>{
      //   console.log("Error: " + err);
      // })
      .catch(err => {
        // Check for duplicate key error
        if (err.response && err.response.data && err.response.data.error) {
          const errorMessage = err.response.data.error;
          if (errorMessage.includes("duplicate key value violates unique constraint")) {
            toast.error("Country name already exists. Please choose a different name.");
          } else {
            toast.error("An error occurred while adding the module.");
          }
        } else {
          toast.error("Unable to connect to the server. Please try again.");
        }
        setShowModal(false);
      });
      
    }else{
      axiosInstance.put("https://kvwio60cfd.execute-api.ap-southeast-2.amazonaws.com/update_admin_countries/",{newCountryName:countryName,countrieId:editingCountry.country_id}).then((response) => {
        // setrefresh(!refresh)
        toast.success(response.data.message);
        setShowModal(false)
        //console.log("response",response);
      }).catch(err=>{
        console.log("Error: " + err);
      })
    }
    



  };


  useEffect(() => {
    axiosInstance.get("https://6nlg761ur6.execute-api.ap-southeast-2.amazonaws.com/get_admin_countries/").then((response)=>{
      //console.log("res",response);
      setCountries(response.data.data)
    }).catch(err=>{
      console.log("Error: " + err);
      
  })

  axiosInstance.get("https://3t749olk1m.execute-api.ap-southeast-2.amazonaws.com/get_admin_users/")
  .then(response => {
    setUsers(response.data.data);  // Store users data
  }).catch(err => {
    console.log("Error fetching users: " + err);
  });

  // axiosInstance.get("https://k5y7a3o1d9.execute-api.ap-southeast-2.amazonaws.com/loginSession/")
  // .then(response => {
  //   setUnacceptedUser(response.data.data);  // Store users data
  // }).catch(err => {
  //   console.log("Error fetching users: " + err);
  // });
  }, [refresh])
     // Check if the organization is assigned to any user
    //  const isOrgAssigned = (countryId) => {
    //   return users.some(user => user.country_id === countryId);
    // };
    // useEffect(() => {
    //   const fetchData = async () => {
    //     try {
    //       // Fetch data from both APIs concurrently
    //       const [usersResponse, sessionResponse] = await Promise.all([
    //         axiosInstance.get("https://lgshvoc3q9.execute-api.ap-southeast-2.amazonaws.com/get_admin_users"),
    //         axiosInstance.get("https://k5y7a3o1d9.execute-api.ap-southeast-2.amazonaws.com/loginSession/"),
    //       ]);
  
    //       // Extract country IDs from both responses
    //       const userCountryIds = usersResponse.data.data.map(user => user.country_id);
    //       const sessionCountryIds = sessionResponse.data.data.map(session => session.country_id);
  
    //       // Combine and remove duplicates
    //       const combinedCountryIds = [...new Set([...userCountryIds, ...sessionCountryIds])];
  
    //       // Update state with combined country IDs
    //       setAssignedCountryIds(combinedCountryIds);
    //       setUsers(usersResponse.data.data);  // Store users data
    //       setUnacceptedUser(sessionResponse.data.data);  // Store session user data
    //     } catch (err) {
    //       console.error("Error fetching data:", err);
    //     }
    //   };
  
    //   fetchData();
    // }, []); // Dependency array (empty means this effect will run only once on mount)
  
    // // Check if the organization is assigned to a given country ID
    // const isOrgAssigned = (countryId) => {
    //   return assignedCountryIds.includes(countryId); // Check if the countryId exists in the combined list
    // };
  
  return (
    <div className="manage-countries-container">
        <ToastContainer autoClose={20000} />
      <div className="header-country">
        {/* <h2>Manage Countries</h2> */}
        <button className='my-2 add-module-btn' onClick={()=>setrefresh(!refresh)}>Refresh Data</button>
        <button className="add-country-btn" onClick={handleAdd}>Add Country</button>
      </div>

      <table className="country-table">
        <thead>
          <tr>
            <th>Country ID</th>
            <th>Country Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {countries ? countries.map((country) => (
            <tr key={country.country_id}>
              <td>{country.country_id}</td>
              <td>{country.country_name}</td>
              <td>
                <button onClick={() => handleEdit(country)}><FontAwesomeIcon icon={faPenToSquare} className="iconuser" /></button>
                <button onClick={() => handleDelete(country.country_id)}><FontAwesomeIcon icon={faTrash} className="iconuser" /></button>
                {/* <button
                  className={`manage-organization-btn ${isOrgAssigned(country.country_id) ? 'disabled-country-btn' : ''}`}
                    onClick={() => handleDelete(country.country_id)}
                   disabled={isOrgAssigned(country.country_id)} // Disable the button
                        >
                       <FontAwesomeIcon icon={faTrash} className="iconuser" />
                          </button> */}
              </td>
            </tr>
          )): <><div className='container'>loading....</div></>}
        </tbody>
      </table>

      {/* Modal for Add/Edit Country */}
      {showModal && (
        <div className="modal-country">
          <div className="modal-content-country">
            <h3>{editingCountry ? 'Edit Country' : 'Add Country'}</h3>
            <label>Country Name:</label>
            <input
              type="text"
              defaultValue={countryName}
              onChange={(e) => setCountryName(e.target.value)}
              placeholder="Country Name"
            />
            <div className="modal-footer-country">
              <button className="btn-country save-country" onClick={handleSave}>Save</button>
              <button className="btn-country cancel-country" onClick={() => setShowModal(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      {/* Confirm Delete Dialog */}
      {showConfirmDialog && (
        <div className="modal-country">
          <div className="modal-content-country">
            <h3>Confirm Delete</h3>
            <p>Are you sure you want to delete this country?</p>
            <div className="modal-footer-country">
              <button className="btn-country save-country" onClick={confirmDelete}>Delete</button>
              <button className="btn-country cancel-country" onClick={() => setShowConfirmDialog(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageCountries;
