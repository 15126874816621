import axios from "axios";
import axiosInstance from "../../../../axiosInterceptor";

export const getS3Url = async (folderName, fileName) => {
  const url =
    "https://xt12a3r7g2.execute-api.ap-southeast-2.amazonaws.com/upload_hcp_files_to_s3_ics_v1";
  return await axiosInstance
    .get(`${url}?folder_name=${folderName}&file_name=${fileName}`)
    .then((res) => res.data.signed_url)
    .catch((err) => {
      throw new Error(err.message);
    });
};
export const getJobRunId = async (s3_uri) => {
  try {
    const response = await axiosInstance.get(
      "https://16me0qw0d6.execute-api.ap-southeast-2.amazonaws.com/call_glue_job_work_flow_tool_ics_v1",
      {
        params: { s3_uri },
      }
    );
    //console.log("GLUE_MAIN:::", response);
    const jobRunId = response.data.job_run_id;
    return jobRunId;
  } catch (err) {
    throw new Error(err.message);
  }
};
export const checkGlueStatus = async (jobRunId) => {
  const url =
    "https://15gzzl6er7.execute-api.ap-southeast-2.amazonaws.com/workflow_check_job_status_s3_to_rds_ics_v1/?job_run_id=";
  try {
    const response = await axiosInstance.get(`${url}${jobRunId}`);
    return response.data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const fileUpload = async (s3Url, formData) => {
  return await axios
    .post(s3Url, formData, {
      headers: "multipart/form-data",
    })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err.message);
    });
};

export const getAuditFiles = async () => {
  const url =
    "https://gkrtpx6lr9.execute-api.ap-southeast-2.amazonaws.com/get_audit_files/";
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
export const getPowerBIFiles = async () => {
  const url =
    "https://4s5yx09bqe.execute-api.ap-southeast-2.amazonaws.com/get_files_for_power_bi_ics_v1";
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
export const getFindingsData = async (body) => {
  const url =
    "https://mbuvgtoocf.execute-api.ap-southeast-2.amazonaws.com/power_bi_findings_data_ics_v1";
  try {
    const response = await axiosInstance.post(url, body);
    //console.log("parsed",response.data.message)
    const parsedData = response.data.message;
    if (typeof parsedData === "string") {
      throw new Error(parsedData.message);
    }
    return parsedData;
  } catch (error) {
    throw new Error(error.message);
  }
};
export const getCodingImprovementsData = async (body) => {
  const url =
    "https://3rxhjj9ya6.execute-api.ap-southeast-2.amazonaws.com/coding_improvements_data_v1_ics_v1";
  try {
    const response = await axiosInstance.post(url, body );
    //console.log('checkcodingimp',response.data.message);
    const parsedData = response.data.message;
    if (typeof parsedData === "string") {
      throw new Error(parsedData.message);
    }
    return parsedData;
  } catch (error) {
    throw new Error(error.message);
  }
};
export const getFocusAreasData = async (body) => {
  const url =
  
    "https://76ples4pp7.execute-api.ap-southeast-2.amazonaws.com/focus_area_data_v2_ics_v1";
  try {
    const response = await axiosInstance.post(url, body );
    //console.log('focusarea',response)
    const parsedData = response.data.message;
    if (typeof parsedData === "string") {
      throw new Error(parsedData.message);
    }
    return parsedData;
  } catch (error) {
    throw new Error(error.message);
  }
};
export const getRcmOverviewData = async (payload) => {
  const url =
    "https://eurf4hbmf2.execute-api.ap-southeast-2.amazonaws.com/rcm_overview_v3_ics_v1";
  try {
    const response = await axiosInstance.post(url, payload);

    //console.log("PARSEDDATA:::::", response)
    const parsedData = response.data;
    if (typeof parsedData.message === "string") {
      throw new Error(parsedData.message);
    }
    return parsedData.message;
  } catch (error) {
    // throw new Error(error.message);
    console.log(error.message)
  }
};
