import axios from "axios";
import axiosInstance from "../../../../axiosInterceptor";
import { useAuth } from "../../../../authContext/AuthContext";

export const updateMessageLikeStatus = async (payload) => {
  const {
    session_token,
    is_liked,
    message_id,
    user_comment_on_message_response = "",
  } = payload;
  const messageLikeUpdateURL =
    "https://conehcanhc.execute-api.ap-southeast-2.amazonaws.com/llm_is_liked_ics_v1";
  return await axiosInstance
    .post(messageLikeUpdateURL, {
      is_liked,
      prompt_id: message_id,
      feedback_text: user_comment_on_message_response,
    })
    .then((res) => {
      const result = res.data.message;
      return result;
    })
    .catch((err) => {
      throw new Error(err.message);
    });
};

const recommendationUrlv2 =
  "https://occymzydnh.execute-api.us-west-2.amazonaws.com/pallas-ai-gen-ai-lambda-func-v4/";
export const getRecommendationData = async (prompt) => {
  const url = `${recommendationUrlv2}?prompt=${encodeURIComponent(prompt)}`;
  return await axiosInstance
    .get(url)
    .then((res) => {
      const content = res.data.pages;
      return content;
    })
    .catch((err) => {
      throw new Error(err.message);
    });
};

export const getChatConversation = async (id, page_number = 1) => {
  const endPoint =
    "https://8z38k49cid.execute-api.ap-southeast-2.amazonaws.com/llm_get_messages_ics_v1/";
  const accessToken = sessionStorage.getItem("accessToken");
  return await axiosInstance
    .post(endPoint, {
      conversation_id: id,
      page_number,
    })
    .then(({ data }) => {
      const messages = data.message;
      if (messages === "No prompts found") {
        return { error: messages };
      }
      if (messages.prompts) {
        messages.prompts.forEach((message) => {
          message.answer = JSON.parse(message.answer);
        });
        const isVersion2 =
          typeof messages.prompts[0].answer.answer === "object";
        return { conversations: messages.prompts, isVersion2 };
      }
    })
    .catch((err) => {
      //console.log("err::::::", err);
      throw new Error(err.message);
    });
};
export const fetchArchiveChat = async (pageNumber = 1) => {
  const archiveApiEndPoint =
    "https://x0gn16cfmd.execute-api.ap-southeast-2.amazonaws.com/llm_get_conversations_ics_v2";
  //const accessToken = sessionStorage.getItem("accessToken");
  const archivePayload = {
    page_number: pageNumber,
    archive: true,
  };
  return await axiosInstance
    .post(archiveApiEndPoint, archivePayload)
    .then((response) => {
      //const { body } = response.data;
      const archivedConversations = response?.data?.message?.conversations;
      return archivedConversations;
    })
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const getReferencesWithPageNumber = async (
  content,
  s3Location,
  isV1 = false
) => {
  const url =
    "https://h07org7so3.execute-api.ap-southeast-2.amazonaws.com/chat_references_optimised_ics_v1";
  let payload;
  if (isV1) {
    payload = {
      content_list: content,
    };
  } else {
    payload = {
      content_list: [
        {
          content: content,
          s3_location: [s3Location],
        },
      ],
    };
  }

  return await axiosInstance
    .post(url, payload)
    .then((res) => {
      //console.log("PAGE_NUMBER::::", res?.data);
      return res.data;
    })
    .catch((err) => {
      throw new Error(err.message);
    });
};
export const getConvo = async (pageNumber = 1) => {
  const getapiEndpoint =
    "https://x0gn16cfmd.execute-api.ap-southeast-2.amazonaws.com/llm_get_conversations_ics_v2";
  //const userID = sessionStorage.getItem("useremail");
  const getpayload = {
    archive: false,
    page_number: pageNumber,
  };
  return await axiosInstance
    .post(getapiEndpoint, getpayload)
    .then((response) => {
      const messages = response.data.message;
      return messages.conversations;
    })
    .catch((error) => {
      //console.log("Error in Get request:", error);
      throw new Error(error.message);
    });
};
