

import React, { useEffect, useState } from "react";
import "./SignUp.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BiLoader, BiShow, BiHide } from "react-icons/bi";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Signup({ setShowLogin }) {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRules, setShowRules] = useState(false);

  const [organizationValue, setorganizationValue] = useState("");
  const [countryValue, setcountryValue] = useState("");

 

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    const payload = {
      username,
      email,
      password,
      organizationId: organizationValue,
      countryId: countryValue,
      role: "user",
    };

    sessionStorage.setItem("organizationId", organizationValue);
    sessionStorage.setItem("countryId", countryValue);

    axios
      .post(
        "https://y3zefj64c8.execute-api.ap-southeast-2.amazonaws.com/register_a_new_user/",
        payload
      )
      .then((res) => {
        const parsedBody = res.data;
        sessionStorage.loginSession = parsedBody.userSub;
        setLoading(false);
        toast.success(res.data.message);
        // toast.success(res.data.message, {
        //   autoClose: 40000, // Set the auto close time to 20 seconds (20000ms)
        // });
        setTimeout(() => {
        setUsername("");
        setEmail("");
        setPassword("");
        setorganizationValue("");
        setcountryValue("");
        setError("");
        setShowLogin(true);
      }, 1000);
      })
      .catch((err) => {
        let errorMessage = err.response?.data;
        if(errorMessage.includes('policy')){
          errorMessage = errorMessage.split("policy:")[1].trim();
        }
        toast.error(errorMessage);
        setError(errorMessage);
        setLoading(false);
      });
  };



  const [organization, setOrganization] = useState([]);
  const [country, setCountry] = useState([]);

  useEffect(() => {
    axios
      .get(
        "https://30acryktt7.execute-api.ap-southeast-2.amazonaws.com/get_admin_organizations/"
      )
      .then((data) => setOrganization(data.data.data));

    axios
      .get(
        "https://6nlg761ur6.execute-api.ap-southeast-2.amazonaws.com/get_admin_countries/"
      )
      .then((data) => setCountry(data.data.data));
  }, []);


  const passwordRules = [
    { normal: "1. At least ", bold: "8 characters." },
    { normal: "2. At least ", bold: "1 uppercase letter." },
    { normal: "3. At least ", bold: "1 lowercase letter." },
    { normal: "4. At least ", bold: "1 number." },
    { normal: "5. At least ", bold: "1 special character." },
  ];
  const tip = { bold: "💡 Tip:", normal: " Use a combination of letters, numbers, and symbols for a strong password!" };

  return (
    <div className="signup-container">
      <ToastContainer autoClose={20000} />

      <form className="signup-form" onSubmit={handleSubmit}>
        <h2>Sign Up</h2>

        {/* Username Input */}
        <div className="input-group">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
              setError("");
            }}
          />
        </div>

        {/* Email Input */}
        <div className="input-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setError("");
            }}
          />
        </div>

        {/* Organization Input */}
        <div className="input-group">
          <label htmlFor="organization">Organization</label>
          <select className="selectorg"
            onChange={(e) => setorganizationValue(e.target.value)}
          >
            <option>select organization</option>
            {organization.map((orga) => (
              <option value={orga.organization_id} key={orga.organization_id}>
                {orga.organization_name}
              </option>
            ))}
          </select>
        </div>

        {/* Country Input */}
        <div className="input-group">
          <label htmlFor="country">Country</label>
          <select className="selectorg" onChange={(e) => setcountryValue(e.target.value)}>
            <option> select country</option>
            {country.map((con) => (
              <option value={con.country_id} key={con.country_id}>
                {con.country_name}
              </option>
            ))}
          </select>
        </div>

        {/* Password Input */}
        <div className="input-group" style={{ position: "relative" }}>
          <label htmlFor="password">Password</label>
          <div
          className="input-group" 
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setError("");
              }}
              onMouseEnter={() => setShowRules(true)}
              onMouseLeave={() => setShowRules(false)}
              style={{ flex: 1, paddingRight: "30px" }}
            />
            <span
              onClick={() => setShowPassword(!showPassword)}
              style={{
                position: "absolute",
                right: "10px",
                cursor: "pointer",
                color: "#888",
              }}
            >
              {showPassword ? <BiHide /> : <BiShow />}
            </span>
          </div>
          {showRules && (
            <div
              className="password-tooltip"
              style={{
                position: "absolute",
                bottom:"20px",
                left: "270px",
                background: "white",
                border: "1px solid #ccc",
                borderRadius: "5px",
                padding: "10px",
                width: "100%",
                marginTop: "5px",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                zIndex: 10,
              }}
            >
              <h6 style={{ margin: 0, padding: "8px", }}>Password Strength Requirements</h6>
              <p  style={{ margin: 0, padding: "8px", }}>Your password must include:</p>
              <ul style={{ margin: 0, padding: "10px", listStyle: "none" }}>
                {passwordRules.map((rule, index) => (
                  <li key={index} style={{ fontSize: "12px", marginBottom: "5px" }}>
                      {rule.normal && <span>{rule.normal}</span>}
                      {rule.bold && <strong>{rule.bold}</strong>}
                  </li>
                ))}
              </ul>
              <p>
                 {tip.bold && <strong>{tip.bold}</strong>}
                 {tip.normal && <span>{tip.normal}</span>}
               </p>
            </div>
          )}
        </div>

        {/* Submit Button */}
        <button className="signup-btn btn btn-warning" type="submit">
          {loading && !error ? <BiLoader /> : "Sign Up"}
        </button>
        {/* {error && <p className="signup-errormessage">{error}</p>} */}

        <div>
          <p className="switchLogin">
            Already have an account?{" "}
            <span onClick={() => setShowLogin(false)}>Login</span>
          </p>
        </div>
      </form>
    </div>
  );
}

export default Signup;
