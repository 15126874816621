import axios from "axios";
import axiosInstance from "../../../../axiosInterceptor";

export const getAuditRows = async (pageNo = 1, filters) => {
  const {header_id,file_type}=filters;
  const parsedArray=header_id.map((id)=>+id);
  const parsedFileType=file_type.flat();
  
  // let payload = "";
  // console.log("PAYLOAD::::", filters);
  // Object.entries(filters).map((filter) => {
  //   if (!filter[1]) {
  //     return;
  //   }
  //   if (typeof filter[1] === "string") {
  //     payload += `&${filter[0]}=${filter[1]}`;
  //     return;
  //   }
  //   filter[1].map((option) => {
  //     payload += `&${filter[0]}=${option}`;
  //   });
  // });
  // console.log("FILTERS:::::", { filters, payload });
  // const v4EndPoint =
  //   "https://x8zya18gei.execute-api.ap-southeast-2.amazonaws.com/filter_api_v4/";
  const v5EndPoint =
    "https://zuphodcc88.execute-api.ap-southeast-2.amazonaws.com/filter_data_ics_v1";
    return await axiosInstance
    .post(
      v5EndPoint,
      {
        ...filters,
        page_number: pageNo,
        header_id:parsedArray,
        file_type:parsedFileType
      },
      {}
    )
    .then((response) => {
      // const data = JSON.parse(response.data.body);
      const data = response.data;
      if (typeof data?.data === "string") {
        throw new Error(response.data.data);
      }
      const auditRows = data.data.audited_rows;
      return auditRows;
    })
    .catch((error) => {
      //console.log("Error in AuditRows Get request:", error);
      throw new Error(error.message);
    });
};

export const getauditData = async (hcp_line_id) => {
  try {
    const getapiEndpointv3 =
    "https://q010ncl49a.execute-api.ap-southeast-2.amazonaws.com/episode_details_ics_v1";
    const response=await axiosInstance.post(getapiEndpointv3,null,{
      params:{
        hcp_line_id:hcp_line_id,
      },
    });
    const auditData = response.data;
    return auditData; 
  } catch (error) {
    //console.log("Error in Auditdetail Get request:", error);
    throw new Error(error.message);
  }
  // return await axiosInstance
  //   .post(getapiEndpointv3, {
  //     params:{
  //       hcp_line_id:id,
  //     }
  //   })
  //   .then((response) => {
  //     const auditData = JSON.parse(response.data.body);
  //     return auditData.message;
  //   })
  //   .catch((error) => {
  //     console.log("Error in Auditdetail Get request:", error);
  //     throw new Error(error.message);
  //   });
};
export const getFilteredAuditData = async (filters,pageNumber=1) => {
  //console.log(filters);
  
  const {header_id,file_type}=filters;
  const parsedArray=header_id.map((id)=>+id);
  const parsedFileType=file_type.flat();
 // const parsedFile=file_type.flat().map(str=>parseInt())
  // const getapiEndpoint =
  //   "https://nw71l5ckl4.execute-api.ap-southeast-2.amazonaws.com/webwork_flow_filter_api/";
  // const v3EndPoint ="https://os3wl7iyn2.execute-api.ap-southeast-2.amazonaws.com/workflow_filter_api_v3/"
  // const v4EndPoint =
  //   "https://x8zya18gei.execute-api.ap-southeast-2.amazonaws.com/filter_api_v4/";
  const v5EndPoint =
    "https://zuphodcc88.execute-api.ap-southeast-2.amazonaws.com/filter_data_ics_v1";
  return await axiosInstance
    .post(v5EndPoint, {
      page_number:pageNumber,
        ...filters,
        header_id:parsedArray,
        file_type:parsedFileType
    })
    .then((response) => {
      if (response.data.errorMessage) {
        throw new Error(response.data.errorMessage);
      }
      const filteredAuditData = response.data.data.audited_rows;
      // console.log('filteredAuditData',filteredAuditData)
      return filteredAuditData;
    })
    .catch((error) => {
      //console.log("Error in FilteredAuditdetail  request:", error);
      throw new Error(error.message);
    });
};

export const getSearchData = async (searchData,flagStatus) => {
  const url =
    "https://2skpo0n1te.execute-api.ap-southeast-2.amazonaws.com/search_by_episode_identifier_ics_v1";
  const params = {
    // episode_identifier: "I516064"
    episode_identifier: searchData,
    is_flagged:flagStatus
  };
  return axiosInstance
    .get(url, { params })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const getSearchRecommendations = async (identifier,flagStatus) => {
  //console.log('string check',typeof(flagStatus),flagStatus);
  
  const url =
    "https://vuvwn413n5.execute-api.ap-southeast-2.amazonaws.com/search_by_episode_identifier_recommendation_ics_v1";
  try {
    const response = await axiosInstance.get(url, {
      params: { episode_identifier: identifier ,is_flagged:flagStatus},
    });
    //console.log('response');
    
    if(response.message){
      //console.log('messge',response.message,response);
      
      return response.message;
    }
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
export const auditStatusUpdate = async (auditDetails) => {
  //console.log('Checkall',auditDetails)
  const {
    findings,
    comments,
    admission_date,
    seperation_date,
    sameday_status,
    episode_identifier,
    file_name,
    insurer_identifier,
    medical_record_number,
    name,
    current_drg_revenue,
    audit_status, // Destructure and omit findings
    ...otherAuditDetails // Collect all other properties
  } = auditDetails;
  const payload = {
    ...otherAuditDetails, // Spread other audit details excluding findings
    is_flagged: false,
    status:auditDetails.audit_status // Set specific flag status
  };
  // const urlv3 =
  //   "https://svga801o97.execute-api.ap-southeast-2.amazonaws.com/work_flow_edit_audit_row_v3/";
  const urlv4 =
    "https://iwci1sogri.execute-api.ap-southeast-2.amazonaws.com/episode_edit_ics_v1/";
  const response = await axiosInstance.put(urlv4,payload);
  if (response.data.errorMessage) {
    throw new Error(response.data.errorMessage);
  }
  const message = response.data.hcp_line_id;
  return message;
};
export const getAuditFiles = async (pageNumber = 1) => {
  const url =
    "https://3zkyrp8och.execute-api.ap-southeast-2.amazonaws.com/get_header_file_ics_v1/";
  try {
    const response = await axiosInstance.get(url,{
      params: {
        page_number: pageNumber,
      },
    });
    return response.data.data.headers;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getPassedRecords = async (pageNo) => {
  const url = `https://8yrsa3a6tf.execute-api.ap-southeast-2.amazonaws.com/get_passed_records_v1/?pageNumber=${pageNo}`;
  try {
    const response = await axios.get(url);
    if (typeof response.data?.message === "string") {
      throw new Error(response.data.message);
    }
    return response.data.message.audited_rows;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getPassedRecordsDetails = async (id) => {
  const url = `https://k5glluopp7.execute-api.ap-southeast-2.amazonaws.com/get_passed_records_detailed_v1/?id=${id}`;
  try {
    const response = await axios.get(url);
    return response.data.meesage;
  } catch (error) {
    throw new Error(error.message);
  }
};
export const getfilteroptionsdata = async () => {
  const url =
    "https://bk9j3m72ua.execute-api.ap-southeast-2.amazonaws.com/filter_hcp_data_ics_v1/";
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
export const recall_episode = async (episodes) => {
  //console.log('zsdf',episodes)
  const payload = {
    page_number: episodes.page_number,
  };
  const endPointUrl =
    "https://6aibqgdtu5.execute-api.ap-southeast-2.amazonaws.com/flagged_episodes_ics_v1/";
    try {
      const response = await axiosInstance.post(endPointUrl, payload);
      return response;  
    } catch (error) {
      return error.message
    }
};
